import './App.css';
import AuthProvider, { useAuth } from './Providers/Auth.js';
import IndexComponent from "./Components/Index.js";
import NotificateProvider from './Providers/NotificateProvider';

function App() {
    return (
        <NotificateProvider>
            <AuthProvider>
                <div className="App">
                    <IndexComponent />
                </div>
            </AuthProvider>
        </NotificateProvider>
    );
}

export default App;
