import { useState } from "react";
import { Api } from '../Components/Api';
import { useTranslation, Trans } from 'react-i18next';
import Button from "./Buttons.js";
import { Icon, IconList } from './Icon';
import Modal from './Modal';
import { useNotificate } from '../Providers/NotificateProvider';

const PersonForm = ({ title, url, onSubmit, onClose }) => {
    const [input, setInput] = useState({
        first_name: "",
        second_name: "",
        third_name: "",
        middle_name: "",
        maiden_name: "",
        gender: 0,
        pob: "",
        dob: "",
        pod: "",
        dod: "",
    });

    const notificate = useNotificate();
    const { t } = useTranslation();

    const [died, setDied] = useState(false);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
        console.log(input);
    };

    const canSubmit = () => {
        return !(input.first_name && input.second_name);
    }

    const handleSubmitEvent = (e) => {
        e.preventDefault();
        try {
            const data = {
                first_name: input.first_name,
                second_name: input.second_name,
                third_name: input.third_name,
                middle_name: input.middle_name,
                maiden_name: input.maiden_name,
                gender: input.gender,
                pob: input.pob,
                pod: input.pod,
            }
            if (input.dob) data.dob = input.dob;
            if (input.dod) data.dod = input.dod;
            Api().post(url, data).then(res => {
                if (res.data) {
                    onSubmit && onSubmit(res.data);
                    return;
                }
                notificate.error(res.data.error);
            });
        } catch (err) {
            notificate.error(err);
        }
    };

    return (
        <div>
            <Modal
                title={title || t("Fill person data")}
                hide_title_buttons={true}
                styles="modal-xl"
                body={
                    <form className="row g-3">
                        <div className="col-md-6">
                            <label className="form-label">{t("Second name")}</label>
                            <input className="form-control" onChange={handleInput}
                                name="second_name" type="text" placeholder={t("Second name")}
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">{t("First name")}</label>
                            <input className="form-control" onChange={handleInput}
                                name="first_name" type="text" placeholder={t("First name")}
                            />
                        </div>
                        <div className="col-md-5">
                            <label className="form-label">{t("Third name")}</label>
                            <input className="form-control" onChange={handleInput}
                                name="third_name" type="text" placeholder={t("Third name")}
                            />
                        </div>
                        <div className="col-md-5">
                            <label className="form-label">{t("Maiden name")}</label>
                            <input className="form-control" onChange={handleInput}
                                name="maiden_name" type="text" placeholder={t("Maiden name")}
                            />
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">{t("Middle name")}</label>
                            <input className="form-control" onChange={handleInput}
                                name="middle_name" type="text" placeholder={t("Middle name")}
                            />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">{t("Date of birth")}</label>
                            <input className="form-control" onChange={handleInput}
                                name="dob" type="date" placeholder={t("Date of birth")}
                                format="DD-MM-YYYY"
                            />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">{t("Gender")}</label>
                            <div>
                                <div className="form-check form-check-inline" title={t("Male")}>
                                    <label htmlFor="gender_male" className="form-check-label">
                                        <Icon icon={IconList.mars}/>
                                    </label>
                                    <input className="form-check-input" onChange={handleInput}
                                        name="gender" type="radio" value={1} id="gender_male"
                                    />
                                </div>
                                <div className="form-check form-check-inline" title={t("Female")}>
                                    <label htmlFor="gender_female" className="form-check-label">
                                        <Icon icon={IconList.venus}/>
                                    </label>
                                    <input className="form-check-input" onChange={handleInput}
                                        name="gender" type="radio" value={-1} id="gender_female"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">{t("Place of birth")}</label>
                            <input className="form-control" onChange={handleInput}
                                name="pob" type="text" placeholder={t("Place of birth")}
                            />
                        </div>
                        <div className="col-md-4">
                            <input className="form-check-input m-1" onChange={(e) => {setDied(!died)}}
                                name="died" type="checkbox"
                            />
                            <label className="form-label">{t("Passed away")}</label>
                        </div>
                        {died && (
                            <div className="col-md-4">
                                <label className="form-label">{t("Date of death")}</label>
                                <input className="form-control" onChange={handleInput}
                                    name="dod" type="date" placeholder={t("Date of death")}
                                    pattern="\d{2}-\d{2}-\d{4}"
                                />
                            </div>
                        )}
                        {died && (
                            <div className="col-md-4">
                                <label className="form-label">{t("Place of death")}</label>
                                <input className="form-control" onChange={handleInput}
                                    name="pod" type="text" placeholder={t("Place of death")}
                                />
                            </div>
                        )}
                    </form>
                }
                footer={
                    <div className="btn-group btn-group-lg">
                        <Button text={t("Save")} color="primary"
                            disabled={canSubmit()}
                            onClick={handleSubmitEvent}
                        />
                        {onClose && <Button text={t("Cancel")} color="secondary"
                            onClick={onClose}
                        />}
                    </div>
                }
            />
        </div>
    );
}

export default PersonForm;
