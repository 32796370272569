import { useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { Api } from './Api.js';
import { toStr } from './DateTime.js';
import { Icon, IconList } from './Icon';
import Button from "./Buttons.js";
import Modal, { Confirm } from './Modal';


export const PhotoUploadComponent = ({ url, onUpload, onCancel }) => {
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const { t } = useTranslation();

    const uploadFiles = () => {
        try {
            setUploading(true);
            const len = files.length;
            var reqs = [];
            for (var i=0; i < len; i++) {
                reqs.push(Api().postFiles(url || 'photos/', { file: files[i] }));
            }
            Promise.all(reqs).then(res => {
                setUploading(false);
                onUpload && onUpload();
           });
        } catch (err) {alert(err)}
    }

    return (
        <div>
            <input type="file" accept="image/*" multiple
                onChange={(e) => setFiles(e.target.files)}
            />
            {files.length > 0 && (
                <div>
                    <hr/>
                    <div className="row">
                        {[...files].map((file, index) => {
                            return (
                                <div key={index} className="col">
                                    {file.name}
                                    <img className="img-thumbnail" alt="no"
                                        src={window.URL.createObjectURL(file)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            <hr/>
            <div className="btn-group btn-group-lg">
                <Button color="primary" text={t("Upload")}
                    onClick={uploadFiles} disabled={uploading || files.length === 0}
                />
                {onCancel && <Button color="secondary" text={t("Cancel")} onClick={onCancel}/>}
            </div>
        </div>
    );
}


export const PhotoUploadModal = ({ url, onUpload, text }) => {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();

    const handleUpload = () => {
        setShow(false);
        onUpload && onUpload();
    }

    return (
        <div>
            {show ? <Modal
                title={t("Photo uploading")}
                body={
                    <PhotoUploadComponent url={url}
                        onUpload={handleUpload}
                        onCancel={() => setShow(false)}
                    />
                }
                styles="modal-xl"
                url={url}
                onClose={() => setShow(false)}
            /> : <Button color="link" text={text || t("Upload")} onClick={() => setShow(true)}/>}
        </div>
    );
}


const PhotosComponent = ({ url, btnUploadTop, btnUploadBottom }) => {
    const [photos, setPhotos] = useState(() => loadPhotos());
    const [photoActive, setPhotoActive] = useState({});
    const [newTitle, setNewTitle] = useState(null);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const { t } = useTranslation();

    function loadPhotos() {
        try {
            Api().get(url || 'photos/').then(res => {
                if (res.data.photos) {
                    setPhotos(res.data.photos);
                } else if (res.data.error) {
                    alert(res.data.error);
                }
            });
        } catch (err) {alert(err)}
    }

    const getUrl = (photo) => {
        return `${process.env.REACT_APP_BACKEND_URL}photos/${photo.pk || photo.photo.pk}/image/`;
    }

    const handleTitleEvent = (e) => {
        e.preventDefault();
        try {
            Api().put(`photos/${photoActive.pk}/`, { title: newTitle }).then(res => {
                if (res.data) {
                    res.data.updated && setPhotoActive(res.data.updated);
                    setNewTitle(null);
                    loadPhotos();
                    return;
                }
                alert(res.data.error);
            });
        } catch (err) {alert(err)}
    };

    const handleDeleteEvent = () => {
        try {
            Api().delete(`photos/${photoActive.pk}/`).then(res => {
                if (res.data) {
                    setPhotoActive({});
                    setNewTitle(null);
                    loadPhotos();
                    return;
                }
                alert(res.data.error);
            });
        } catch (err) {alert(err)}
    };

    const getPhoto = (obj) => {
        return obj.photo || obj;
    }

    return (
        <div>
            {photoActive && photoActive.pk && <Modal
                title={newTitle ? (
                    <form className="form-inline row">
                        <input className="form-control col" type="text"
                            onChange={(e) => setNewTitle(e.target.value)}
                            value={newTitle}
                        />
                        <div className="col">
                            <div className="btn-group float-start">
                                <Button color="primary" title={t("Save")}
                                    text={<Icon icon={IconList.yes}/>}
                                    onClick={handleTitleEvent}
                                />
                                <Button color="secondary" title={t("Cancel")}
                                    text={<Icon icon={IconList.no}/>}
                                    onClick={() => setNewTitle(null)}
                                />
                            </div>
                        </div>
                    </form>
                ) : <span
                    onClick={() => setNewTitle(photoActive.title)}
                    title={t("Click to edit title")}
                >{photoActive.title || '-'}</span>}
                styles="modal-xl"
                onClose={() => setPhotoActive({})}
                body={
                    <a href={`${getUrl(photoActive)}`} target="_blank" rel="noreferrer">
                        <img className="img-fluid" alt="no" src={getUrl(photoActive)}/>
                    </a>
                }
                footer={
                    <div className="row">
                        <div className="col-6">
                            {photoActive.latitude} {photoActive.longitude}
                        </div>
                        <div className="col-6">
                            {photoActive.datetime && toStr(photoActive.datetime)}
                        </div>
                        <div className="col-12">
                            <Button color="danger" title={t("Delete")} style="float-end"
                                text={<Icon icon={IconList.delete}/>}
                                onClick={() => setDeleteConfirm(true)}
                            />
                        </div>
                        {deleteConfirm && <Confirm title={t("Delete confirmation")}
                            text={t("Do you want to delete photo?")}
                            onClose={() => setDeleteConfirm(false)}
                            onConfirm={handleDeleteEvent}
                        />}
                    </div>
                }
            />}
            {btnUploadTop && <PhotoUploadModal onUpload={loadPhotos} url={url}
                text={<Icon icon={IconList.upload}/>}
            />}
            <br/>
            <div className="row">
                {photos && photos.map((photo, index) => {
                    return (
                        <div key={index} className="col-3">
                            <img alt="no"
                                src={getUrl(photo)}
                                className="img-thumbnail"
                                onClick={() => setPhotoActive(getPhoto(photo))}
                            />
                            {photo.title}
                        </div>
                    );
                })}
            </div>
            {btnUploadBottom && <PhotoUploadModal onUpload={loadPhotos} url={url}
                text={<Icon icon={IconList.upload}/>}
            />}
        </div>
    );
}

export default PhotosComponent;
