import { useState } from 'react';
import { useContext, createContext } from 'react'
import { useTranslation } from 'react-i18next';

const NotificateContext = createContext()

const NotificateProvider = ({ children }) => {
    const [objects, setObjects] = useState([]);
    const [counter, setCounter] = useState(1);
    const { t } = useTranslation();

    const add = (text, color='secondary', title=null) => {
        setObjects(prev => [...prev, {text, title, color, pk: counter}]);
        setCounter(prev => prev+1);
    }

    const error = (text, title=null) => {
        add(text, 'danger', title);
    }

    const pop = (obj) => {
        setObjects(prev => prev.filter(x => x !== obj));
    }

    return (
        <NotificateContext.Provider value={{ add, pop, error }}>
            {children}
            {objects.length > 0 && (
                <div className="fixed-bottom row">
                    <div className="col-sm-12 col-md-8 col-lg-6 ms-auto">
                        {objects.slice(objects.length > 5 ? objects.length - 5 : 0).reverse().map((obj, key) => {
                            return (
                                <div key={key} className={`alert alert-dismissible fade show alert-${obj.color}`}>
                                    {obj.title && <h6 className="alert-heading">{obj.title}</h6>}
                                    {obj.text}
                                    <button type="button" title={t("Close")} className="btn-close" onClick={() => pop(obj)}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </NotificateContext.Provider>
    );
}

export default NotificateProvider;

export const useNotificate = () => {
    return useContext(NotificateContext);
}
