import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useNotificate } from '../Providers/NotificateProvider';
import { Icon, IconList } from './Icon';
import { Api } from './Api.js';
import Button, { ConfirmButton } from "./Buttons.js";
import { getAge } from "./DateTime.js";
import PersonForm from "./PersonForm.js";
import PhotosComponent from './Photo.js';
import Modal from './Modal';
import Relatives from './Relatives.js';

const PersonComponent = () => {
    const { t } = useTranslation();
    const notificate = useNotificate();

    const { personPk } = useParams();
    const [person, setPerson] = useState(loadData);
    const [editing, setEditing] = useState(false);
    const [editPerson, setEditPerson] = useState({
        first_name: '',
        second_name: '',
        third_name: '',
        middle_name: '',
        maiden_name: '',
        gender: 0,
        pob: '',
        dob: '',
        pod: '',
        dod: '',
    });
    const [exField, setField] = useState({ name: '', value: ''});
    const [editingBio, setEditingBio] = useState(false);
    const [bio, setBio] = useState(null);
    const [died, setDied] = useState(false);
    const [showInvite, setShowInvite] = useState(false);
    const [inviteUrl, setInviteUrl] = useState('');

    function getUrl() {
        return personPk ? `persons/${personPk}/` : 'me/';
    }

    function loadData() {
        try {
            Api().get(getUrl()).then(res => {
                if (res.data.person) {
                    setPerson(res.data.person);
                } else if (res.data.error) {
                    notificate.error(res.data.error);
                }
            });
        } catch (err) {console.log(err)}
    }

    const handleInput = (e) => {
        const { name, value } = e.target;
        setEditPerson((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFieldInput = (e) => {
        const { name, value } = e.target;
        setField((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const addField = () => {
        const url = `persons/${person.pk}/fields/`;
        try {
            Api().post(url, exField).then(res => {
                if (res.data.added) {
                    loadData();
                    setField({ name: '', value: ''});
                } else if (res.data.error) notificate.error(res.data.error);
            });
        } catch (err) {console.log(err)}
    }

    const editField = (pk) => {
        const url = `persons/${person.pk}/fields/${pk}/`;
        try {
            Api().put(url, exField).then(res => {
                if (res.data.updated) {
                    loadData()
                    setField({ name: '', value: ''});
                } else if (res.data.error) notificate.error(res.data.error);
            });
        } catch (err) {console.log(err)}
    }

    const deleteField = (pk) => {
        const url = `persons/${person.pk}/fields/${pk}/`;
        try {
            Api().delete(url).then(res => {
                if (res.data.deleted) {
                    loadData()
                } else if (res.data.error) notificate.error(res.data.error);
            });
        } catch (err) {console.log(err)}
    }

    function startEditing() {
        setEditPerson(person);
        setEditing(true);
        person.dod ? setDied(true) : setDied(false);
    }

    function getInviteUrl() {
        try {
            Api().get(getUrl() + 'invite/').then(res => {
                if (res.data.url) {
                    setInviteUrl(`${process.env.REACT_APP_FRONTEND_URL}invite/${res.data.token}/`);
                    setShowInvite(true);
                } else if (res.data.error) {
                    notificate.error(res.data.error);
                }
            });
        } catch (err) {console.log(err)}
    }

    const savePerson = (e) => {
        e && e.preventDefault();
        try {
            Api().put(getUrl(), editPerson).then(res => {
                if (res.data.updated) {
                    setPerson(res.data.updated);
                    setEditing(false);
                } else if (res.data.error) notificate.error(res.data.error);
            });
        } catch (err) {console.log(err)}
    }

    const saveBio = () => {
        try {
            Api().put(getUrl(), { bio: bio }).then(res => {
                if (res.data.updated) {
                    setPerson(res.data.updated);
                    setEditingBio(false);
                } else if (res.data.error) {
                    notificate.error(res.data.error);
                }
            });
        } catch (err) {console.log(err)}
    }

    return (
        <div className="container">
            {person && person.pk ? (
                <div className="row">
                    {showInvite && <Modal styles="modal-lg" title={t('Invite')}
                        body={
                            <div>
                                <h5>Share the link with {person.first_name}</h5>
                                <div className="alert alert-success mt-3">
                                    {inviteUrl}
                                    <Button color="success" style="m-1" title={t('Copy')}
                                        text={<Icon icon={IconList.copy}/>}
                                        onClick={() => navigator.clipboard.writeText(inviteUrl)}
                                    />
                                </div>
                            </div>
                        }
                        onClose={() => setShowInvite(false)}
                    />}
                    <h1 className="col-12 mb-3">
                        {person.fullname}, {getAge(person.dob, person.dod)}
                    </h1>
                    <div className="col-6 border p-3">
                        <PhotosComponent
                            url={`persons/${person.pk}/photos/`}
                            btnUploadBottom={true}
                        />
                    </div>
                    <div className="col-6 border p-3">
                        {editing ? (
                            <form onSubmit={savePerson}>
                                <table className="table table-striped table-hover text-center align-middle">
                                    <tbody>
                                        <tr>
                                            <th>{t('Second name')}</th>
                                            <td>
                                                <input className="form-control" onChange={handleInput}
                                                    name="second_name" type="text" placeholder={t('Second name')}
                                                    value={editPerson.second_name}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('Maiden name')}</th>
                                            <td>
                                                <input className="form-control" onChange={handleInput}
                                                    name="maiden_name" type="text" placeholder={t('Maiden name')}
                                                    value={editPerson.maiden_name}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('First name')}</th>
                                            <td>
                                                <input className="form-control" onChange={handleInput}
                                                    name="first_name" type="text" placeholder={t('First name')}
                                                    value={editPerson.first_name}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('Third name')}</th>
                                            <td>
                                                <input className="form-control" onChange={handleInput}
                                                    name="third_name" type="text" placeholder={t('Third name')}
                                                    value={editPerson.third_name}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('Middle name')}</th>
                                            <td>
                                                <input className="form-control" onChange={handleInput}
                                                    name="middle_name" type="text" placeholder={t('Middle name')}
                                                    value={editPerson.middle_name}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('Gender')}</th>
                                            <td>
                                                <div className="form-check form-check-inline">
                                                    <label htmlFor="gender_male" className="form-check-label">
                                                        <Icon icon={IconList.mars}/>
                                                    </label>
                                                    <input className="form-check-input" onChange={handleInput}
                                                        name="gender" type="radio" value={1} id="gender_male"
                                                        checked={editPerson.gender == 1}
                                                    />
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <label htmlFor="gender_female" className="form-check-label">
                                                        <Icon icon={IconList.venus}/>
                                                    </label>
                                                    <input className="form-check-input" onChange={handleInput}
                                                        name="gender" type="radio" value={-1} id="gender_female"
                                                        checked={editPerson.gender == -1}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('Date of birth')}</th>
                                            <td>
                                                <input className="form-control" onChange={handleInput}
                                                    name="dob" type="date" placeholder={t('Date of birth')}
                                                    value={editPerson.dob}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('Place of birth')}</th>
                                            <td>
                                                <input className="form-control" onChange={handleInput}
                                                    name="pob" type="text" placeholder={t('Place of birth')}
                                                    value={editPerson.pob}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('Passed away')}</th>
                                            <td>
                                                <input className="form-check-input" onChange={(e) => {setDied(!died)}}
                                                    type="checkbox" checked={died}
                                                />
                                            </td>
                                        </tr>
                                        {died && (
                                            <tr>
                                                <th>{t('Date of death')}</th>
                                                <td>
                                                    <input className="form-control" onChange={handleInput}
                                                        name="dod" type="date" placeholder={t('Date of death')}
                                                        value={editPerson.dod}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                        {died && (
                                            <tr>
                                                <th>{t('Place of death')}</th>
                                                <td>
                                                    <input className="form-control" onChange={handleInput}
                                                        name="pod" type="text" placeholder={t('Place of death')}
                                                        value={editPerson.pod}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <div className="btn-group">
                                    <Button color="primary" text={t("Save")} role="submit" />
                                    <Button color="secondary" text={t("Cancel")} onClick={() => setEditing(false)}/>
                                </div>
                            </form>
                        ) : (
                            <div>
                                <table className="table table-striped table-hover text-center align-middle">
                                    <tbody>
                                        <tr>
                                            <th>{t('Second name')}</th>
                                            <td>{person.second_name}{person.maiden_name && ` (${person.maiden_name})`}</td>
                                        </tr>
                                        <tr><th>{t('First name')}</th><td>{person.first_name}</td></tr>
                                        {person.third_name && <tr><th>{t('Third name')}</th><td>{person.third_name}</td></tr>}
                                        {person.middle_name && <tr><th>{t('Middle name')}</th><td>{person.middle_name}</td></tr>}
                                        <tr><th>{t('Gender')}</th><td>
                                            {person.gender == 1 ? <Icon icon={IconList.mars} title={t("Male")}/> :
                                            person.gender == -1 ? <Icon icon={IconList.venus} title={t("Female")}/> :
                                            <Icon icon={IconList.question} title={t("Unknown")}/>}
                                        </td></tr>
                                        <tr><th>{t('Date of birth')}</th><td>{person.dob}</td></tr>
                                        {person.dod && <tr><th>{t('Date of death')}</th><td>{person.dod}</td></tr>}
                                        {person.pob && <tr><th>{t('Place of birth')}</th><td>{person.pob}</td></tr>}
                                        {person.pod && <tr><th>{t('Place of death')}</th><td>{person.pod}</td></tr>}
                                        {!person.dod && (
                                            <tr>
                                                <th>{t('User')}</th>
                                                <td>
                                                    {person.user ? <Icon icon={IconList.yes}/> : <Button color="link" title={t("Invite")}
                                                        text={<Icon icon={IconList.send}/>}
                                                        onClick={getInviteUrl}
                                                    />}
                                                </td>
                                            </tr>
                                        )}
                                        {person.fields && person.fields.map((field, key) => {
                                            return (
                                                <tr key={key}>
                                                    <th>
                                                        {field.name}
                                                        <ConfirmButton color="link" style="btn-sm"
                                                            title={t("Edit")} icon="edit" header={`Edit ${field.name}`}
                                                            yes={t("Save")} yesColor="primary" no={t("Cancel")}
                                                            question={
                                                                <table className="table text-center align-middle">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>{t('Name')}</th>
                                                                            <td>
                                                                                <input className="form-control" onChange={handleFieldInput}
                                                                                    name="name" type="text" placeholder={t('Name')}
                                                                                    value={exField.name} autoFocus={true}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>{t('Value')}</th>
                                                                            <td>
                                                                                <input className="form-control" onChange={handleFieldInput}
                                                                                    name="value" type="text" placeholder={t('Value')}
                                                                                    value={exField.value}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            }
                                                            onOpen={() => setField({ name: field.name, value: field.value })}
                                                            onClose={() => setField({ name: '', value: '' })}
                                                            onConfirm={() => editField(field.pk)}
                                                        />
                                                        <ConfirmButton color="link" style="btn-sm"
                                                            title={t("Delete")} icon="delete"
                                                            question={`Do you want to delete ${field.name}?`}
                                                            onConfirm={() => deleteField(field.pk)}
                                                        />
                                                    </th>
                                                    <td>
                                                        {field.value}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="float-end">
                                    <Button color="link" style="btn-sm"
                                        title={t("Edit")} icon="edit"
                                        onClick={startEditing}
                                    />
                                    <ConfirmButton color="link" style="btn-sm"
                                        title={t("Add extra field")} icon="add" header={t("Adding extra field")}
                                        yes={t("Save")} yesColor="primary" no={t("Cancel")}
                                        question={
                                            <table className="table text-center align-middle">
                                                <tbody>
                                                    <tr>
                                                        <th>{t('Name')}</th>
                                                        <td>
                                                            <input className="form-control" onChange={handleFieldInput}
                                                                name="name" type="text" placeholder={t('Name')}
                                                                value={exField.name} autoFocus={true}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>{t('Value')}</th>
                                                        <td>
                                                            <input className="form-control" onChange={handleFieldInput}
                                                                name="value" type="text" placeholder={t('Value')}
                                                                value={exField.value}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        }
                                        onConfirm={addField}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-12">
                        <hr/>
                        <div className="row">
                            <div className="col-3">
                                <Relatives person={person} type="parents" refreshParent={loadData} />
                            </div>
                            <div className="col-3">
                                <Relatives person={person} type="siblings" refreshParent={loadData} />
                            </div>
                            <div className="col-3">
                                <Relatives person={person} type="spouses" refreshParent={loadData} />
                            </div>
                            <div className="col-3">
                                <Relatives person={person} type="children" refreshParent={loadData} />
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="col border p-3">
                        <div className="float-end">
                            <Button color="link" style="m-2" title={t('Edit lifestory')}
                                icon="edit" onClick={() => {setBio(person.bio);setEditingBio(true)}}
                            />
                        </div>
                        {editingBio && <Modal styles="modal-xl" title={t('Biography')}
                            body={
                                <textarea className="form-control" rows={30}
                                    onChange={(e) => setBio(e.target.value)}
                                    value={bio}
                                />
                            }
                            onClose={() => setEditingBio(false)}
                            footer={
                                <div className="btn-group btn-group-lg">
                                    <Button color="primary" text={t("Save")} onClick={saveBio}/>
                                    <Button color="secondary" text={t("Cancel")} onClick={() => setEditingBio(false)}/>
                                </div>
                            }
                        />}
                        <pre className="m-1 p-1 fs-6">
                            {person.bio || <span className="fs-6 fst-italic">{t("No life story")}</span>}
                        </pre>
                    </div>
                </div>
            ) : <PersonForm title={t("Fill your personal data")} url="me/" />}
        </div>
    );
}

export default PersonComponent;

export const Person = ({ person, hide_link, buttons }) => {
    const { t } = useTranslation();

    const getGender = () => {
        if (person.gender == 1) return <Icon icon={IconList.mars} title={t("Male")}/>;
        else if (person.gender == -1) return <Icon icon={IconList.venus} title={t("Female")}/>;
        return '';
    }

    return (
        <div className="card overflow-hidden specific-w-300 specific-w-sm-450 mw-100">
            <div className="row">
                <div className="col-1">
                    {person.avatar && (
                        <img className="img-fluid"
                            src={`${process.env.REACT_APP_BACKEND_URL}photos/${person.avatar}/image/`}
                        />
                    )}
                </div>
                <div className="col">
                    <div className="card-body">
                        <div className="card-title mb-1">
                            {getGender()} {person.first_name} {person.second_name}
                            {person.dob && <span title={person.dob}>, {getAge(person.dob, person.dod)}</span>} {person.dod && <Icon icon={IconList.dead} title={t("Passed away")}/>}
                            <div className="float-end">
                                {buttons}
                                {!hide_link && (
                                    <a className="link-secondary m-2" title={t("Open profile")}
                                        href={`/persons/${person.pk}/`}
                                    >
                                        <Icon icon={IconList.link} />
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
