import i18n from 'i18next';
import { i18nextPlugin } from 'translation-check'
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import transEN from './locales/en/translation.json';
import transRU from './locales/ru/translation.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(i18nextPlugin)
    .init({
        debug: false,
        fallbackLng: ['en', 'ru'],
        saveMissing: true,
        interpolation: { escapeValue: false },
        resources: {
            en: {
                translation: transEN,
            },
            ru: {
                translation: transRU,
            },
        }
    });

export default i18n;
