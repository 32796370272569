import axios from 'axios';

export const ApiBase = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    validateStatus: (s) => s >= 200 && s <= 500,
});

export const Api = () => {
    const defaultOptions = {
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
            'Content-Type': 'application/json',
        },
        validateStatus: (s) => s >= 200 && s <= 500,
    };

    let instance = axios.create(defaultOptions);

    instance.interceptors.request.use(function (config) {
        const token = localStorage.getItem('user_id');
        const csrf = localStorage.getItem('csrf');
        config.headers.Authorization =  token ? `Token ${token}` : '';
        config.headers['X-CSRFToken'] =  csrf || '';
        return config;
    });

    instance.postFiles = (url, data) => {
        let formData = new FormData();
        for ( var key in data ) {
            formData.append(key, data[key]);
        }
        return instance.post(url, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
    }

    instance.putFiles = (url, data) => {
        let formData = new FormData();
        for ( var key in data ) {
            formData.append(key, data[key]);
        }
        return instance.put(url, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
    }

    return instance;
};
