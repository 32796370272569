import React from 'react';
import { useState } from 'react';
import { useNotificate } from '../Providers/NotificateProvider';
import { useTranslation, Trans } from 'react-i18next';
import { Api } from './Api.js';
import { getAge } from "./DateTime.js";
import { Icon, IconList } from './Icon';
import Paginator from './Paginator';

export default function PersonListComponent(props) {
    const { t } = useTranslation();
    const notificate = useNotificate();

    const [sortField, setSortField] = useState(null);
    const [search, setSearch] = useState('');
    const [persons, setPersons] = useState(loadData);
    const [pages, setPages] = useState({});

    function loadData() {
        try {
            var url = 'persons/';
            var params = [];
            if (sortField) params.push({ name: 'sort', value: sortField});
            if (search) params.push({ name: 'search', value: search});
            if (pages && pages.page && pages.page > 1) params.push({ name: 'page', value: pages.page });
            if (params) url += '?' + params.map(param => `${param.name}=${param.value}`);
            Api().get(url).then(res => {
                if (res.data.results) {
                    setPersons(res.data.results);
                    setPages({
                        count: res.data.count,
                        pages: res.data.pages,
                        page: res.data.page,
                    });
                } else if (res.data.error) {
                    notificate.error(res.data.error);
                }
            });
        } catch (err) {console.log(err)}
    }

    React.useEffect(loadData, [search, sortField]);

    function sortBy(field) {
        if (sortField === field) field = '-' + sortField;
        if (sortField !== field) setSortField(field);
    }

    function sortedBy(field) {
        if (sortField === field) return 'sortup';
        if (sortField === '-' + field) return 'sortdown';
        return 'sort';
    }

    function changePage(page) {
        pages.page = page;
        setPages(pages);
        loadData();
    }

    return (
        <div>
            <div className="text-centered">
                <input className="form-control" onChange={(e) => setSearch(e.target.value)}
                    type="text" placeholder={t("Search")} value={search}
                />
            </div>
            <table className="table table-striped table-hover text-center align-middle">
                <tbody>
                    <tr>
                        <th>#</th>
                        <th>
                            <a role="button" onClick={() => {sortBy('second_name')}}>{t('Second name')}</a>
                            &nbsp;
                            <Icon icon={IconList[sortedBy('second_name')]}/>
                        </th>
                        <th>
                            <a role="button" onClick={() => {sortBy('first_name')}}>{t('First name')}</a>
                            &nbsp;
                            <Icon icon={IconList[sortedBy('first_name')]}/>
                        </th>
                        <th>
                            <a role="button" onClick={() => {sortBy('third_name')}}>{t('Third name')}</a>
                            &nbsp;
                            <Icon icon={IconList[sortedBy('third_name')]}/>
                        </th>
                        <th>{t('Gender')}</th>
                        <th>
                            <a role="button" onClick={() => {sortBy('dob')}}>{t('Age')}</a>
                            &nbsp;
                            <Icon icon={IconList[sortedBy('dob')]}/>
                        </th>
                    </tr>
                    {persons && persons.map((person, key) => {
                        return (
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>
                                    <a className="link-secondary m-2" title={t("Open profile")} href={`/persons/${person.pk}/`}>
                                        {person.second_name}{person.maiden_name && ` (${person.maiden_name})`}
                                    </a>
                                </td>
                                <td>{person.first_name}</td>
                                <td>{person.third_name}</td>
                                <td>
                                    {person.gender == 1 ? <Icon icon={IconList.mars} title={t("Male")}/> :
                                    person.gender == -1 ? <Icon icon={IconList.venus} title={t("Female")}/> :
                                    <Icon icon={IconList.question} title={t("Unknown")}/>}
                                </td>
                                <td>{getAge(person.dob, person.dod)} {person.dod && <Icon icon={IconList.dead} title={t("Passed away")}/>}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Paginator
                pages={pages.pages}
                page={pages.page}
                count={pages.count}
                onChange={changePage}
            />
        </div>
    )
}
