import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleCheck, faCircleXmark, faPlus, faCheck, faPaperPlane,
    faPenToSquare, faTrash, faDownload, faInfoCircle, faGear,
    faArrowRotateLeft, faClock, faFloppyDisk, faFileLines, faCopy,
    faUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter,
    faCirclePlay, faCircleStop, faRightFromBracket, faMars, faVenus, faCircleQuestion,
    faUpload, faPersonWalkingWithCane, faRestroom, faChildren, faUser, faHeart,
    faArrowRightFromBracket, faMagnifyingGlass, faCross, faSort, faSortUp, faSortDown,
    faAngleLeft, faAnglesLeft, faAngleRight, faAnglesRight,
} from '@fortawesome/free-solid-svg-icons'

export function Icon(props) {
    return <FontAwesomeIcon icon={props.icon} size={props.size} title={props.title}/>
}

export const IconList = {
    out: faRightFromBracket,
    yes: faCircleCheck,
    no: faCircleXmark,
    check: faCheck,
    question: faCircleQuestion,

    add: faPlus,
    edit: faPenToSquare,
    delete: faTrash,
    copy: faCopy,
    info: faInfoCircle,
    time: faClock,
    save: faFloppyDisk,
    logs: faFileLines,
    search: faMagnifyingGlass,

    refresh: faArrowRotateLeft,
    receive: faDownload,
    send: faPaperPlane,
    start: faCirclePlay,
    stop: faCircleStop,
    upload: faUpload,

    config: faGear,
    fullscreen: faUpRightAndDownLeftFromCenter,
    normalscreen: faDownLeftAndUpRightToCenter,
    link: faArrowRightFromBracket,

    sort: faSort,
    sortup: faSortUp,
    sortdown: faSortDown,
    arrowLeft: faAngleLeft,
    arrowsLeft: faAnglesLeft,
    arrowRight: faAngleRight,
    arrowsRight: faAnglesRight,

    person: faUser,
    mars: faMars,
    venus: faVenus,
    parents: faPersonWalkingWithCane,
    siblings: faRestroom,
    children: faChildren,
    spouses: faHeart,
    dead: faCross,
}
