import { useTranslation } from 'react-i18next';
import { useAuth } from '../Providers/Auth.js';
import { LoginComponent, RegisterComponent, LogoutButton } from './Account.js';
import Button from "./Buttons.js";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PersonListComponent from './PersonList.js';
import PersonComponent from './Person.js';
import PhotosComponent from './Photo.js';

const lngs = {
    en: { nativeName: 'EN' },
    ru: { nativeName: 'РУ' }
};

const IndexComponent = () => {
    const user = useAuth();
    const { t, i18n } = useTranslation();

    return (
        <div>
            {user.token ? (
                <div>
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="/">
                                {t('Rod')}
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-collapse-1" aria-controls="navbar-collapse-1" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbar-collapse-1">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a href="/me" className={window.location.pathname == '/me' ? "nav-link active": "nav-link"}>
                                            {user.user}
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/family" className={window.location.pathname == '/family' ? "nav-link active": "nav-link"}>
                                            {t('Family')}
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/photos" className={window.location.pathname == '/photos' ? "nav-link active": "nav-link"}>
                                            {t('Photos')}
                                        </a>
                                    </li>
                                </ul>
                                <div className="d-flex">
                                    <div className="btn-group btn-group-sm">
                                        {Object.keys(lngs).map((lng, key) => <Button key={key}
                                            color={i18n.resolvedLanguage === lng ? 'light' : 'dark'}
                                            onClick={() => {console.log(i18n.resolvedLanguage);i18n.changeLanguage(lng)}}
                                            text={lngs[lng].nativeName}
                                        />)}
                                    </div>
                                    <LogoutButton />
                                </div>
                            </div>
                        </div>
                    </nav>
                    <BrowserRouter>
                      <Routes>
                          <Route path="/photos" element={<PhotosComponent />} />
                          <Route path="/family" element={<PersonListComponent />} />
                          <Route path="/persons/:personPk/" element={<PersonComponent />} />
                          <Route path="/*" element={<PersonComponent />} />
                      </Routes>
                    </BrowserRouter>
                </div>
            ) : (
                <BrowserRouter>
                  <Routes>
                      <Route path="/invite/:token/" element={<RegisterComponent />} />
                      <Route path="*" element={<LoginComponent />} />
                  </Routes>
                </BrowserRouter>
            )}
        </div>
    );
}

export default IndexComponent;
