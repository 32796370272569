import { useState } from 'react';
import { Icon, IconList } from './Icon';
import Modal, { Confirm } from './Modal';

export default function Button(props) {
    let styles = 'btn';
    if (props.style) styles += ' ' + props.style;
    if (props.color) styles += ' btn-outline-' + props.color;
    return (
        <button className={styles} onClick={props.onClick} title={props.title} disabled={props.disabled}>
            {props.icon ? <Icon icon={IconList[props.icon]}/> : props.text}
        </button>
    );
}

export const ModalButton = (props) => {
    const [isOpen, setOpen] = useState(props.default || false);

    const open = () => {
        setOpen(true);
        props.onOpen && props.onOpen();
    }

    const close = () =>  {
        setOpen(false);
        props.onClose && props.onClose();
    }

    return (
        isOpen ? <Modal
            title={props.header}
            onClose={close}
            body={props.bodyCalc ? props.bodyCalc() : props.body}
            footer={props.footer}
            styles={props.dialog_styles}
            headerButtons={props.headerButtons}
        /> : <Button
            color={props.color}
            style={props.style}
            onClick={open}
            title={props.title}
            icon={props.icon}
            text={props.text}
            disabled={props.disabled}
        />
    )
}

export const ConfirmButton = (props) => {
    const [isOpen, setOpen] = useState(props.default || false);

    const open = () => {
        setOpen(true);
        props.onOpen && props.onOpen();
    }

    const close = () =>  {
        setOpen(false);
        props.onClose && props.onClose();
    }

    return (
        isOpen ? <Confirm
            title={props.header}
            text={props.question}
            onConfirm={props.onConfirm}
            onClose={close}
            yes={props.yes}
            no={props.no}
            yesColor={props.yesColor}
        /> : <Button
            color={props.color}
            style={props.style}
            onClick={open}
            title={props.title}
            icon={props.icon}
            text={props.text}
            disabled={props.disabled}
        />
    )
}
