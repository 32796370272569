import { useState } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from "../Providers/Auth";
import { Redirect } from "react-router-dom";
import { ApiBase } from './Api';
import Button from "./Buttons";
import { Icon, IconList } from './Icon';
import Modal from './Modal';
import { useNotificate } from '../Providers/NotificateProvider';

export const LoginComponent = () => {
    const [input, setInput] = useState({
        username: "",
        password: "",
    });
    const [errorMsg, setErrorMsg] = useState(null);

    const auth = useAuth();
    const { t } = useTranslation();

    const handleSubmitEvent = (e) => {
        e.preventDefault();
        auth.loginAction(input, null, setErrorMsg);
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({...prev, [name]: value}));
    };

    return (
        <Modal
            title="Login"
            hide_title_buttons={true}
            body={
                <div className="container">
                    {errorMsg && (
                        <div className="alert alert-danger">
                            {errorMsg}
                        </div>
                    )}
                    <form onSubmit={handleSubmitEvent}>
                        <input
                            className="form-control m-1"
                            type="username"
                            id="user-username"
                            name="username"
                            placeholder={t("Username")}
                            onChange={handleInput}
                            autoFocus
                        />
                        <input
                            className="form-control m-1"
                            type="password"
                            id="password"
                            name="password"
                            placeholder={t("Password")}
                            onChange={handleInput}
                        />
                        <br/>
                        <Button text={t("Login")} color="primary" style="btn-lg"
                            disabled={input.username === "" || input.password === ""}
                        />
                    </form>
                </div>
        }
    />);
};

export const RegisterComponent = () => {
    const { t } = useTranslation();
    const notificate = useNotificate();

    const { token } = useParams();
    const [tokenExists, setTokenExists] = useState(() => checkToken());
    const [input, setInput] = useState({
        username: "",
        email: "",
        password: "",
        password1: "",
    });
    const [errors, setErrors] = useState({});
    const [done, setDone] = useState(false);

    function checkToken() {
        try {
            ApiBase.get(`account/invite/${token}/`).then(res => {
                if (res.data.invite) {
                    setTokenExists(res.data.invite);
                } else if (res.data.error) {
                    notificate.error(res.data.error);
                }
            });
        } catch (err) {alert(err)}
    }

    const handleSubmitEvent = (e) => {
        e.preventDefault();
        try {
            ApiBase.post(`account/invite/${token}/`, {
                username: input.username,
                email: input.email,
                password: input.password,
            }).then(res => {
                if (res.data.user) {
                    setDone(true);
                } else if (res.data.error) {
                    setErrors(res.data.error);
                    notificate.error(res.data.error);
                }
            });
        } catch (err) {alert(err)}
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const getInputClass = (name) => {
        let s = 'form-control m-1';
        if (errors && errors[name]) s = s + ' is-invalid';
        return s;
    }

    return (
        <Modal
            title="Registration by invite link"
            hide_title_buttons={true}
            body={tokenExists ? (
                <div className="container">
                    {done ? (
                        <div className="alert alert-success">
                            Registration complete. Please go to <a href="/">Login</a>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmitEvent}>
                            <div className="input-group has-validation">
                                <input
                                    className={getInputClass('username')}
                                    type="username"
                                    id="user-username"
                                    name="username"
                                    placeholder={t("Username")}
                                    onChange={handleInput}
                                    autoFocus
                                />
                                <div className="invalid-feedback">
                                    {errors.username}
                                </div>
                            </div>
                            <div className="input-group has-validation">
                                <input
                                    className={getInputClass('email')}
                                    type="email"
                                    id="user-email"
                                    name="email"
                                    placeholder={t("Email")}
                                    onChange={handleInput}
                                />
                                <div className="invalid-feedback">
                                    {errors.email}
                                </div>
                            </div>
                            <div className="input-group has-validation">
                                <input
                                    className={getInputClass('password')}
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder={t("Password")}
                                    onChange={handleInput}
                                />
                                <div className="invalid-feedback">
                                    {errors.password}
                                </div>
                            </div>
                            <div className="input-group has-validation">
                                <input
                                    className="form-control m-1"
                                    type="password"
                                    id="password1"
                                    name="password1"
                                    placeholder={t("Confirm password")}
                                    onChange={handleInput}
                                />
                            </div>
                            <Button text={t("Register")} color="primary" style="btn-lg mt-3" type="submit"
                                disabled={input.username === "" || input.password === "" || input.password !== input.password1}
                            />
                        </form>
                    )}
                </div>
            ) : <div className="alert alert-danger">Token not found</div>
        }
    />);
};

export const LogoutButton = () => {
    const auth = useAuth();
    const { t } = useTranslation();

    return (
        <Button color="link"
            onClick={() => auth.logOut()}
            title={t("Logout")}
            text={<Icon icon={IconList.out}/>}
        />
    );
};
