import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Api, ApiBase } from '../Components/Api';
import { useNotificate } from '../Providers/NotificateProvider';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('user_id') || null);
    const [user, setUser] = useState(checkUser);

    const notificate = useNotificate();

    function checkUser() {
        try {
            Api().get('me/').then(res => {
                if (res.data.person) {
                    setUser(res.data.person.fullname);
                } else {
                    localStorage.removeItem('user_id');
                    setUser(null);
                }
            });
        } catch (err) {
            notificate.error(err);
        }
    }

    const loginAction = async (data, onSuccess, onError) => {
        try {
            ApiBase.post('account/', data).then(res => {
                if (res.data.user && res.data.token) {
                    setUser(res.data.user);
                    setToken(res.data.token);
                    localStorage.setItem('user_id', res.data.token);
                    onSuccess && onSuccess(res.data);
                } else {
                    setUser(null);
                    res.data.error && onError && onError(res.data.error);
                }
            });
        } catch (err) {
            onError && onError(err);
        }
    };

    const logOut = () => {
        setUser(null);
        setToken(null);
        localStorage.removeItem('user_id');
    };

    return (
        <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};
