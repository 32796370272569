String.prototype.toHHMMSS = function () {
    var sec_num = parseInt(this, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) {hours = "0" + hours;}
    if (minutes < 10) {minutes = "0" + minutes;}
    if (seconds < 10) {seconds = "0" + seconds;}
    return hours + ':' + minutes + ':' + seconds;
}

export function toStr(date) {
    return new Date(date).toISOString().replace('T', ' ');
}

export function toPretty(date) {
    if (date) {
        const now = new Date();
        const dat = new Date(date);
        const secs = Math.abs(now - dat) / 1000;
        if (secs < 60) return `${Math.floor(secs)}s`;
        if (secs < 60*60) return `${Math.floor(secs/60)}m`;
        if (secs < 24*60*60) return `${Math.floor(secs/3600)}h`;
        return `${Math.floor(secs/86400)}d`;
    }
}

export default function DateTime(props) {
    let style = '';
    if (props.min || props.max) {
        const now = new Date();
        const dat = new Date(props.date);
        const secs = Math.abs(now - dat) / 1000;
        if (props.min && secs < props.min) style = 'text-success'
        else if (props.max && secs > props.max) style = 'text-danger'
    }
    return (
        <span title={toStr(props.date)} className={style}>
            {toPretty(props.date)}
        </span>
    )
}

export function getAge(dob, dod) {
    const date1 = new Date(dob);
    const date2 = dod ? new Date(dod) : new Date();
    var years = (date2.getFullYear() - date1.getFullYear());
    if (date2.getMonth() < date1.getMonth() ||
        date2.getMonth() == date1.getMonth() && date2.getDate() < date1.getDate()) {
        years--;
    }
    return years;
}
