import { useState } from 'react';
import { Icon, IconList } from './Icon';

export default function Paginator(props) {
    const [pages, setPages] = useState({});

    function getPages() {
        const pagesAround = 9;
        const page = props.page || 1;
        return [...Array(pagesAround).keys()].reverse().map(x => page - x + Math.floor(pagesAround / 2)).filter(x => x >= 1 && x <= props.pages);
    }

    return (
        <div className="row m-2">
            {props.pages && props.pages > 1 && (
                <div className="col-8 text-start">
                    <div className="btn-group">
                        {getPages().map((num) => {
                            return (
                                <button
                                    key={`page-${num}`}
                                    className={'btn btn-outline-secondary' + (props.page === num ? ' active' : '')}
                                    onClick={() => props.onChange && props.onChange(num)}
                                >
                                    {num}
                                </button>
                            )
                        })}
                    </div>
                </div>
            )}
            <div className="col-4">
                <div className="btn-group float-end">
                    {props.page > 1 &&
                        <button className={'btn btn-outline-secondary'} onClick={() => props.onChange && props.onChange(1)}>
                            <Icon icon={IconList.arrowsLeft}/>
                        </button>
                    }
                    {props.page > 1 &&
                        <button className={'btn btn-outline-secondary'} onClick={() => props.onChange && props.onChange(props.page - 1)}>
                            <Icon icon={IconList.arrowLeft}/>
                        </button>
                    }
                    {props.page < props.pages &&
                        <button className={'btn btn-outline-secondary'} onClick={() => props.onChange && props.onChange(props.page + 1)}>
                            <Icon icon={IconList.arrowRight}/>
                        </button>
                    }
                    {props.page < props.pages &&
                        <button className={'btn btn-outline-secondary'} onClick={() => props.onChange && props.onChange(props.pages)}>
                            <Icon icon={IconList.arrowsRight}/>
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}
