import { useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { Api } from './Api.js';
import Button from "./Buttons.js";
import { Icon, IconList } from './Icon';
import Modal, { Confirm } from './Modal';
import { useNotificate } from '../Providers/NotificateProvider';
import { Person } from './Person.js';
import PersonForm from "./PersonForm.js";

const Relatives = ({ person, type, refreshParent }) => {
    const [relatives, setRelatives] = useState(() => loadData());
    const [unlinkRelative, setUnlinkRelative] = useState({});
    const [adding, setAdding] = useState(false);
    const [searching, setSearching] = useState(false);
    const [suggest, setSuggest] = useState([]);
    const [search, setSearch] = useState('');
    const [searchPersons, setSearchPersons] = useState([]);
    const [personSelect, setPersonSelect] = useState({});

    const { t } = useTranslation();
    const notificate = useNotificate();

    function getUrl() {
        return `persons/${person.pk}/${type}/`;
    }

    function loadData() {
        try {
            Api().get(getUrl()).then(res => {
                if (res.data[type]) {
                    setRelatives(res.data[type]);
                } else if (res.data.error) {
                    notificate.error(res.data.error);
                }
            });
        } catch (err) {
            notificate.error(err);
        }
    }

    function loadSuggest() {
        try {
            Api().get(getUrl() + 'suggest/').then(res => {
                if (res.data.persons) {
                    setSuggest(res.data.persons);
                    setPersonSelect({});
                } else if (res.data.error) {
                    notificate.error(res.data.error);
                }
            });
        } catch (err) {
            notificate.error(err);
        }
    }

    function loadBySearch() {
        try {
            Api().get(`persons/?search=${search}`).then(res => {
                if (res.data.persons) {
                    setSearchPersons(res.data.persons);
                    setPersonSelect({});
                } else if (res.data.error) {
                    notificate.error(res.data.error);
                }
            });
        } catch (err) {
            notificate.error(err);
        }
    }

    function relatePerson() {
        const uuid = personSelect.pk;
        setPersonSelect({});
        try {
            Api().put(getUrl(), { uuid }).then(res => {
                if (res.data.updated) {
                    loadData();
                    loadSuggest();
                    refreshParent && refreshParent();
                } else if (res.data.error) {
                    notificate.error(res.data.error);
                }
            });
        } catch (err) {
            notificate.error(err);
        }
    }

    function unrelatePerson() {
        const uuid = unlinkRelative.pk;
        setUnlinkRelative({});
        try {
            Api().delete(getUrl() + uuid + '/').then(res => {
                if (res.data.deleted) {
                    loadData();
                    refreshParent && refreshParent();
                } else if (res.data.error) {
                    notificate.error(res.data.error);
                }
            });
        } catch (err) {
            notificate.error(err);
        }
    }

    function convertType() {
        if (type == 'parents') return t('Parents');
        if (type == 'siblings') return t('Siblings');
        if (type == 'children') return t('Children');
        if (type == 'spouses') return t('Spouses');
        return t('Unknown');
    }

    return (
        <div className="p-3">
            {adding && <PersonForm
                title={`Adding ${type} of ${person.first_name}`}
                url={`persons/${person.pk}/${type}/`}
                onSubmit={() => {loadData();setAdding(false);refreshParent && refreshParent()}}
                onClose={() => setAdding(false)}
            />}
            {searching && <Modal styles='modal-lg'
                title={`Adding ${type} from list`}
                onClose={() => setSearching(false)}
                body={
                    <div>
                        {suggest && suggest.length > 0 && (
                            <div className="alert alert-success">
                                {suggest.map((prsn) => {
                                    return <Person key={prsn.pk} person={prsn} hide_link
                                        buttons={
                                            <Button color="primary" title={t("Add")} icon="add"
                                                onClick={() => setPersonSelect(prsn)}
                                            />
                                        }
                                    />
                                })}
                            </div>
                        )}
                        <form className="row mb-2 mt-4">
                            <div className="col-10">
                                <input className="form-control"
                                    type="text" placeholder={t("Type search line")}
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                />
                            </div>
                            <div className="col">
                                <Button color="primary"
                                    text={t("Search")}
                                    onClick={(e) => {e.preventDefault();loadBySearch()}}
                                    disabled={search.length < 3}
                                />
                            </div>
                        </form>
                        {searchPersons && searchPersons.length > 0 && (
                            <div className="alert alert-info">
                                {searchPersons.map((prsn) => {
                                    return <Person key={prsn.pk} person={prsn} hide_link
                                        buttons={
                                            <Button color="primary" title={t("Add")} icon="add"
                                                onClick={() => setPersonSelect(prsn)}
                                            />
                                        }
                                    />
                                })}
                            </div>
                        )}
                    </div>
                }
            />}
            {personSelect && personSelect.pk && <Confirm
                text={<Trans i18nKey="LinkPerson" values={{name: personSelect.first_name, type: convertType() }}/>}
                onConfirm={relatePerson}
                onClose={() => setPersonSelect({})}
            />}
            <div className="float-end">
                <Button color="link" style="btn-sm"
                    title={t("Add new")} icon="add"
                    onClick={() => setAdding(true)}
                />
                <Button color="link" style="btn-sm"
                    title={t("Add from list")}
                    icon="search"
                    onClick={() => {loadSuggest();setSearching(true)}}
                />
            </div>
            <Icon icon={IconList[type]} />
            <span className="m-2 text-capitalize">{convertType()}</span>
            <hr/>
            {relatives && relatives.length > 0 ? relatives.map((relative) => <Person
                key={relative.pk} person={relative}
                buttons={
                    <Button color="link" style="btn-sm" title={t("Unlink")} icon='no'
                        onClick={() => setUnlinkRelative(relative)}
                    />
                }
            />) : <span>-</span>}
            {unlinkRelative && unlinkRelative.pk && <Confirm
                text={<Trans i18nKey="UnlinkPerson" values={{ name: unlinkRelative.first_name, type: convertType() }}/>}
                onConfirm={unrelatePerson}
                onClose={() => setUnlinkRelative({})}
            />}
        </div>
    )
}

export default Relatives;
